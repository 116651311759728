'use client';

import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';

import FeaturesSection from '@/components/HomePage/FeatureSection';
import HeroSection from '@/components/HomePage/HeroSection';
import PlansOffered from '@/components/HomePage/PlansOffered';

import Loading from './loader';

const CoursesOffered = dynamic(() => import('@/components/HomePage/CouresOffered'), {
  loading: () => <Loading />,
  ssr: false,
});

// const MediaPresence = dynamic(() => import('@/components/HomePage/MediaPresence'), {
//   loading: () => <Loading />,
//   ssr: false,
// });

const Testimonials = dynamic(() => import('@/components/HomePage/Testimonials'), {
  loading: () => <Loading />,
  ssr: false,
});

const ServicesBanner = dynamic(() => import('@/components/HomePage/ServicesBanner'));
const TestSeries = dynamic(() => import('@/components/HomePage/TestSeries'));
const OurFaculties = dynamic(() => import('@/components/HomePage/OurFaculties'));
const PopularBatches = dynamic(() => import('@/components/HomePage/PopularBatches'));

export default function Page() {
  return (
    <section>
      <HeroSection />
      <FeaturesSection />
      <PlansOffered />

      <Suspense fallback={<Loading />}>
        <ServicesBanner />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <CoursesOffered />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <TestSeries />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <OurFaculties />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PopularBatches />
      </Suspense>

      {/* <Suspense fallback={<Loading />}>
        <MediaPresence />
      </Suspense> */}

      <Suspense fallback={<Loading />}>
        <Testimonials />
      </Suspense>
    </section>
  );
}
