import React from 'react';
import Image from 'next/image';

interface FeatureCardProps {
  Icon: string;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ Icon, title, description }) => {
  return (
    <div className="group relative flex h-[17rem] cursor-pointer flex-col items-center justify-center bg-primary-gray p-4 font-inter transition-colors duration-300 hover:bg-primary-green lg:min-w-[17rem]">
      <div className="absolute -top-8 mb-4 flex size-16 items-center justify-center rounded-full bg-primary-gray shadow-md ring-[6px] ring-white group-hover:bg-primary-green">
        <Image src={Icon} alt={title} width={24} height={24} />
      </div>
      <h3 className="mb-4 mt-7 self-start text-base font-semibold group-hover:text-white">{title}</h3>
      <p className="text-sm leading-relaxed text-gray-700 group-hover:text-white">{description}</p>
    </div>
  );
};

export default FeatureCard;
