import React from 'react';
import { featuresData } from '@/constant/HomepageConst';

import FeatureCard from '../common/FeatureCard';

const FeaturesSection = () => {
  return (
    <section className="mx-auto my-10 w-4/5 p-4 font-inter lg:max-w-screen-xl">
      <h2 className="mb-2 text-center font-medium text-primary-green">Welcome to Medway</h2>
      <p className="mb-12 text-center text-2xl font-semibold">Choose Medway to Get Success in Medical Exams</p>
      <div className="relative grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        {featuresData.map((feature) => (
          <FeatureCard key={feature.id} Icon={feature.icon} title={feature.title} description={feature.description} />
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
