import React from 'react';

type LoadingSizeType = 'small' | 'default' | 'large';

interface LoadingProps {
  size?: LoadingSizeType;
}

const Loading: React.FC<LoadingProps> = ({ size = 'default' }) => {
  const sizeClasses: Record<LoadingSizeType, string> = {
    small: 'h-16',
    default: 'h-32',
    large: 'h-48',
  };

  return (
    <div className={`w-full ${sizeClasses[size]} flex items-center justify-center`}>
      <div className="relative">
        {/* Outer spinner */}
        <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-t-blue-500" />

        {/* Inner spinner */}
        <div
          className="absolute left-1 top-1 h-10 w-10 animate-spin rounded-full border-4 border-gray-200 border-t-blue-400"
          style={{ animationDirection: 'reverse', animationDuration: '0.6s' }}
        />

        {/* Loading text */}
        <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap text-sm text-gray-500">
          Loading...
        </span>
      </div>
    </div>
  );
};

export default Loading;
