import React, { useState } from 'react';
import Image from 'next/image';
import FreeTrialBanner from '@/assets/Images/homepage-free-trial.webp';
import { useAppSelector } from '@/store/hooks';

import LoginCard from '../common/LoginCard';

const HeroSection = () => {
  const { isAuthenticated } = useAppSelector((state: any) => state.auth);
  const [isVisible, setIsVisible] = useState(false);
  return (
    <section className="mb-12 flex w-full flex-col items-center justify-center bg-custom-gradient px-4 pt-2 text-center font-inter sm:px-6">
      <h2 className="pt-6 text-xl font-semibold tracking-tight text-dark-green sm:text-4xl md:text-5xl">
        Achieve Your Medical Dreams with Expert Guidance
      </h2>
      <p className="w-full max-w-md px-4 py-5 text-sm leading-loose text-dark-green sm:max-w-lg sm:text-lg md:max-w-3xl">
        At Medway you receive training for NEXT, NEET PG, and FMGE exams with advanced educational methods and
        individual mentorship that helps you develop better confidence to achieve success.
      </p>
      {!isAuthenticated && (
        <button
          onClick={() => setIsVisible(true)}
          className="mb-8 mt-4 rounded-full bg-primary-green px-6 py-3 text-xs text-white shadow-lg hover:bg-green-600 sm:text-base"
        >
          Start your Free Trial
        </button>
      )}
      <div className="flex justify-center">
        <Image
          src={FreeTrialBanner}
          alt="Mockup 1"
          width={700}
          height={400}
          quality={45}
          placeholder="blur"
          sizes="(max-width: 640px) 90vw, 700px"
          priority={true}
          className="h-auto w-full max-w-[700px]"
          style={{ objectFit: 'contain' }}
        />
      </div>
      {isVisible && <LoginCard onClose={() => setIsVisible(false)} />}
    </section>
  );
};

export default HeroSection;
