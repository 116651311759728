import CommunityIcon from '@/assets/Icons/Homepage/community.svg';
import EducatorIcon from '@/assets/Icons/Homepage/educator.svg';
import GraduatedIcon from '@/assets/Icons/Homepage/graduated.svg';
import InnovationIcon from '@/assets/Icons/Homepage/innovation.svg';
import BatchImage1 from '@/assets/Images/Reels/batch1.png';
import BatchImage2 from '@/assets/Images/Reels/batch2.png';
import BatchImage3 from '@/assets/Images/Reels/batch3.png';
import BatchImage4 from '@/assets/Images/Reels/batch4.png';

export const featuresData = [
  {
    id: 1,
    icon: EducatorIcon,
    title: 'Expert Medical Educators',
    description: `Get a chance to learn from professionals who understand the challenges of medical exams and are committed to your success.`,
  },
  {
    id: 2,
    icon: GraduatedIcon,
    title: 'Student-Centered Approach',
    description: `Our medical preparation programs are personalized, ensuring every student can learn at their own pace to reach their full potential.
`,
  },
  {
    id: 3,
    icon: InnovationIcon,
    title: 'Innovative, Modern Learning',
    description: `With our progressive teaching techniques and technology, you can always find yourself one step ahead of others.`,
  },
  {
    id: 4,
    icon: CommunityIcon,
    title: 'Collaborative Environment',
    description: `Live in a stimulating environment where knowledge exchange and peer learning combine to provide a thorough educational experience.`,
  },
];

export const coursesData = [
  {
    id: 1,
    title: 'NEET PG Exam Preparation',
    description:
      'Through our NEET PG preparation program, you can learn the syllabus extensively through expert instructional classes and practice sessions that combine group discussions for achieving confidence for success.',
    // link: '/neet-pg-preparation',
  },
  {
    id: 2,
    title: 'NEET UG Exam Preparation',
    description:
      'The NEXT Exam coverage of our NEET UG preparation program provides expert guidance together with interactive discussions and practice sessions to help you succeed on your exam.',
    // link: '/neet-ug-preparation',
  },
  {
    id: 3,
    title: 'FMGE Exam Preparation',
    description:
      'The FMGE preparation course at our center delivers comprehensive training specifically designed for overseas medical graduates to excel in the exam format and syllabus before entering medical profession.',
    // link: '/fmge-preparation',
  },
];

export const instagramData = [
  { src: BatchImage1, url: 'link_to_instagram_post1' },
  { src: BatchImage2, url: 'link_to_instagram_post2' },
  { src: BatchImage3, url: 'link_to_instagram_post2' },
  { src: BatchImage4, url: 'link_to_instagram_post2' },
];
