'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import BgPlanGradient from '@/assets/Images/Banner/bg-plan-gradient.png';
import { CURRENCY } from '@/constant/PlansData';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setSelectedPlan, setViewDetail } from '@/store/slices/PlansSlice';
import { PlansApi, PlansDetailsApi } from '@/store/thunks/Plans/PlansThunks';

import LoginCard from '../common/LoginCard';
import PlanDetailModel from '../PlanDetail/PlanDetailModel';

const PlansOffered = () => {
  const dispatch = useAppDispatch<any>();
  const [activeTab, setActiveTab] = useState('MASTER PLAN');
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const { data } = useAppSelector((state) => state?.plan);
  const activePlans = data?.find((plan: any) => plan?.planType === activeTab);
  const { isAuthenticated } = useAppSelector((state: any) => state.auth);
  const { selectedPlan } = useAppSelector((state) => state.plan);
  const countOfSelectedPlans = Object.values(selectedPlan).filter(Boolean).length;
  const handleCheckboxChange = (id: any) => {
    dispatch(setSelectedPlan(id));
  };
  const handleProceedButton = () => {
    if (selectedPlan.length > 0) {
      dispatch(PlansDetailsApi(selectedPlan[0]));
    }
    if (!isAuthenticated) {
      setIsLoginVisible(true);
    } else {
      setIsVisible(true);
    }
  };

  const masterPlan = data.find((item: any) => item.planType === 'MASTER PLAN');
  const subjectPlan = data.find((item: any) => item.planType === 'SUBJECT PLAN');
  const selectedPlanIds = selectedPlan.map((plan: any) => plan);
  const coveredPlanIds = masterPlan
    ? masterPlan.courses
        .filter((course: any) => {
          const isSelected = selectedPlanIds.includes(course.id);

          return isSelected;
        })
        .flatMap((course: any) => course.coveredPlanIds || [])
    : [];
  const uniqueCoveredPlanIds = [...new Set(coveredPlanIds)];
  const matchingSubjectPlanIds =
    subjectPlan?.courses
      ?.filter((course: any) => uniqueCoveredPlanIds.includes(course.id))
      .map((course: any) => course.id) || [];

  const handleViewDetailButton = (plan: any) => {
    dispatch(setViewDetail([plan?.id]));
    dispatch(PlansDetailsApi([plan?.id]));
    setIsVisibleDetail(true);
  };
  useEffect(() => {
    dispatch(PlansApi());
  }, []);

  useEffect(() => {
    if (isVisible || isVisibleDetail) {
      // Disable scroll when modal is visible
      document.body.style.overflow = 'hidden';
    } else {
      // Enable scroll when modal is closed
      document.body.style.overflow = 'auto';
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible, isVisibleDetail]);

  const selectedPlansDetails = data?.flatMap((tab: any) =>
    tab.courses.filter((plan: any) => selectedPlan.includes(plan.id)),
  );

  const totalOriginalPrice = selectedPlansDetails?.reduce((sum: number, plan: any) => sum + plan.originalPrice, 0);

  const totalDiscountedPrice = selectedPlansDetails?.reduce((sum: number, plan: any) => sum + plan.discountedPrice, 0);

  return (
    <div className="mx-auto my-10 max-w-[90%] px-4 py-5 font-inter sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
      <h2 className="text-center text-2xl font-bold text-primary-green sm:text-3xl">
        Affordable Study Plans to Ace Your Exams!
      </h2>
      <p className="mt-2 text-center text-sm font-normal text-dark-gray sm:text-base">
        Value-for-money study plans by experts for every student. Choose the plan that fits your study needs and exam
        goals.
      </p>

      {/* Tabs */}
      <div className="relative my-8">
        {/* Gradient Background */}
        <Image
          src={BgPlanGradient}
          alt="Plan gradient"
          width={1000}
          height={250}
          className="absolute -bottom-28 left-1/2 -z-10 max-w-[80%] -translate-x-1/2 opacity-80 sm:max-w-full"
        />

        <div className="relative flex flex-col rounded-lg border bg-white shadow-lg">
          <div className="flex overflow-x-auto whitespace-nowrap rounded-t-lg bg-primary-gray text-sm sm:text-base">
            {data?.map((tab: any) => (
              <button
                key={tab.planType}
                onClick={() => setActiveTab(tab.planType)}
                className={`w-full rounded-t-lg p-4 ${
                  activeTab === tab.planType
                    ? 'bg-white font-semibold text-secondary-green'
                    : 'font-medium text-dark-gray'
                }`}
              >
                {tab.planType}
              </button>
            ))}
          </div>

          {/* Plan Cards */}
          <div className="flex-1 overflow-auto py-4">
            {activePlans?.courses && activePlans.courses.length > 0 ? (
              activePlans.courses.map((plan: any, index: any) => {
                return (
                  <div
                    key={plan?.id}
                    className="flex w-full flex-col items-center gap-4 rounded-lg border p-4 sm:flex-row sm:justify-between"
                  >
                    {/* Plan Title with Checkbox */}
                    <div className="flex w-full items-center gap-4 sm:w-1/3 sm:text-start">
                      {/* Checkbox */}
                      <input
                        disabled={matchingSubjectPlanIds.includes(plan?.id)}
                        data-testid={`checkbox-${index}`}
                        type="checkbox"
                        checked={
                          selectedPlan.some((p: any) => p === plan?.id) || matchingSubjectPlanIds?.includes(plan?.id)
                        }
                        onChange={() => handleCheckboxChange(plan.id)}
                        className="h-5 w-5 shrink-0"
                      />
                      <p className="text-base font-medium text-dark-gray">{plan?.title}</p>
                    </div>

                    <div className="flex w-full flex-wrap items-center justify-between gap-4 sm:justify-end">
                      {/* Pricing Section */}
                      <div className="flex flex-grow flex-row items-center gap-2 text-base font-medium sm:w-auto">
                        <p className="whitespace-nowrap text-dark-gray line-through">₹{plan?.originalPrice}</p>
                        <p className="whitespace-nowrap text-secondary-green">₹{plan?.discountedPrice}</p>
                        <span className="min-w-[80px] whitespace-nowrap px-2 text-center text-sm font-medium text-dark-gray sm:text-base">
                          {plan?.discount}% OFF
                        </span>
                      </div>

                      {/* View Details Button */}
                      <div className="flex flex-grow flex-row sm:w-auto sm:flex-grow-0 sm:flex-col sm:items-center sm:justify-center">
                        <button
                          data-testid={`viewdetail-btn-${index}`}
                          className="truncate rounded-full border border-primary-green px-4 py-1.5 text-sm font-medium text-secondary-green sm:mt-2 sm:px-6 sm:py-1 sm:text-base"
                          onClick={() => handleViewDetailButton(plan)}
                        >
                          View Details
                        </button>
                      </div>
                    </div>

                    {/* Plan Detail Model */}
                    {isVisibleDetail && <PlanDetailModel from={'view'} onClose={() => setIsVisibleDetail(false)} />}
                  </div>
                );
              })
            ) : (
              <p className="text-center text-red-500">No Plans available</p>
            )}
          </div>

          {countOfSelectedPlans > 0 && (
            <div className="fixed bottom-0 left-0 z-50 flex w-full justify-between rounded-t-3xl bg-primary-green p-4 shadow-lg">
              {/* Left Section: Selected Items Count */}
              <div className="ml-3">
                <p className="text-sm font-medium text-white">{countOfSelectedPlans} Item Selected</p>
                <div className="flex flex-row">
                  <p className="text-xl font-extrabold text-slate-200">
                    {CURRENCY}
                    {totalDiscountedPrice}
                  </p>
                  <p className="ml-3 text-slate-200">*without GST</p>
                </div>

                <p className="text-slate-200 line-through">
                  {' '}
                  {CURRENCY}
                  {totalOriginalPrice}
                </p>
              </div>

              {/* Right Section: Proceed Button */}
              <button
                data-testid="proceed-btn"
                className="bg-primary-blue rounded-lg px-6 py-3 font-semibold text-white"
                onClick={handleProceedButton}
              >
                PROCEED
              </button>
            </div>
          )}
          {/* <div className="pb-24"></div> */}
          {isAuthenticated && isVisible && <PlanDetailModel from="" onClose={() => setIsVisible(false)} />}
          {!isAuthenticated && isLoginVisible && <LoginCard onClose={() => setIsLoginVisible(false)} />}
        </div>
      </div>
    </div>
  );
};

export default PlansOffered;
